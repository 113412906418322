body .service-detail {
  padding: 20px;
  width: 80%;
  max-width: 100%;
  margin: 0 auto;
}

.service-detail h2 {
  font-size: 36px;
  margin-bottom: 30px;
  color: #003a70;
}

.service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #f0f0f0;
  color: #333;
  text-decoration: none;
  border-radius: 5px;
}

.back-button:hover {
  background-color: #002a50;
  color: #fff;
}

.status {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.status-all.services.operating.normally {
  color: green;
}

.status-service.disruption {
  color: red;
}

.status-operational {
  color: #28a745;
}

.status-degraded {
  color: #ffc107;
}

.status-outage {
  color: #dc3545;
}

.service-detail .service-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.main-column {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.service-issues,
.historic-issues {
  width: 100%;
  margin-bottom: 20px;
}

.side-column {
  width: 25%;
  margin-top: 0;
  padding-left: 20px;
}

.mentions {
  margin-top: 20px;
  font-size: 1.1em;
}

.status,
.mentions {
  font-size: 18px;
  margin-bottom: 15px;
}

.issues-table-container,
.historic-issues-table-container {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.issues-table,
.historic-issues-table {
  width: 100%;
  border-collapse: collapse;
}

.issues-table th,
.issues-table td,
.historic-issues-table th,
.historic-issues-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.issues-table th,
.historic-issues-table th {
  background-color: #d0d7d7;
  font-weight: bold;
}

.debug-info {
  background-color: #d8d8d8;
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.debug-info h3 {
  color: #003a70;
  margin-bottom: 20px;
  font-size: 24px;
}

.debug-item {
  display: flex;
  margin-bottom: 10px;
}

.debug-label {
  font-weight: bold;
  width: 200px;
  color: #6c757d;
}

.debug-value {
  flex: 1;
}

.tweets {
  margin-top: 0px;
}

.tweets h3 {
  color: #003a70;
  margin-bottom: 20px;
  font-size: 24px;
}

.tweets ul {
  list-style-type: none;
  padding: 0;
}

.tweets li {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.timeline-control {
  margin-bottom: 10px;
}

.tweet-list {
  list-style-type: none;
  padding: 0;
}

.tweet-item {
  background-color: #f5f8fa;
  border: 1px solid #e1e8ed;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.tweet-date {
  color: #657786;
  font-size: 0.8em;
  display: block;
  margin-top: 5px;
}

@media screen and (max-width: 1024px) {
  body .service-detail {
    width: 95%;
  }

  .service-detail .service-content {
    flex-direction: column;
  }

  .main-column,
  .side-column {
    width: 100%;
  }

  .side-column {
    padding-left: 0;
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .service-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .service-detail h2 {
    font-size: 28px;
  }

  .issues-table-container,
  .historic-issues-table-container {
    max-height: none;
  }

  .issues-table,
  .historic-issues-table {
    font-size: 14px;
  }

  .issues-table th,
  .issues-table td,
  .historic-issues-table th,
  .historic-issues-table td {
    padding: 8px;
  }

  .debug-item {
    flex-direction: column;
  }

  .debug-label {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 480px) {
  body .service-detail {
    width: 100%;
    padding: 10px;
  }

  .service-detail h2 {
    font-size: 24px;
  }

  .back-button {
    font-size: 14px;
    padding: 8px 12px;
  }

  .tweets li {
    padding: 10px;
  }
}
