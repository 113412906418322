.dashboard {
  padding: 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.service-header img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.service-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.service-card.red {
  background-color: #ffcccb;
  border-left: 5px solid #dc3545;
}

.service-card.amber {
  background-color: #ffe5b4;
  border-left: 5px solid #ffc107;
}

.service-card.green {
  background-color: #90ee90;
  border-left: 5px solid #28a745;
}

.service-card.clickable:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.service-link {
  text-decoration: none;
  color: inherit;
}

.service-card h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333333;
}

.status,
.mentions {
  margin: 10px 0;
  font-size: 16px;
}

.status-all-services-operating-normally,
.status-service-disruption {
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
}

.status-all-services-operating-normally {
  background-color: #28a745;
}

.status-service-disruption {
  background-color: #dc3545;
}

.service-issues {
  margin-top: 20px;
}

.issues-table-container {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.issues-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.issues-table th,
.issues-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.issues-table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  font-weight: bold;
  color: #495057;
}

.issues-table tr:last-child td {
  border-bottom: none;
}

.issues-table tr {
  cursor: default;
}

.issues-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: stretch;
}

.issues-grid .service-card {
  flex: 1 1 calc(33.33% - 20px);
  min-width: 300px;
  max-width: none;
  display: flex;
  flex-direction: column;
}

.issues-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px 0;
}

.all-issues-acknowledged {
  margin: 0;
  background-color: #28a745;
  color: white;
  font-weight: bold;
  padding: 0 10px;
  border-radius: 5px;
  line-height: 0.2;
}

.x-logo {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  vertical-align: middle;
  margin-right: 5px;
  display: inline-block; /* This ensures inline alignment with text */
}

.reddit-logo {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  vertical-align: middle;
  margin-right: 5px;
  display: inline-block; /* This ensures inline alignment with text */
}

.mentions {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mentions span {
  margin-left: 5px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
  }
  100% {
    transform: perspective(400px) rotateY(360deg);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.service-logo-animate-fade {
  animation: fade 2s infinite;
}

.service-logo-animate-flip {
  animation: flip 2s infinite;
}

.service-logo-animate-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
}

.service-logo-animate-swing {
  animation: swing 2s infinite;
}

.service-logo {
  transition: all 0.3s ease;
}

.service-logo-animate-pulse {
  animation: pulse 2s infinite;
}

.service-logo-animate-rotate {
  animation: rotate 4s linear infinite;
}

.service-logo-animate-bounce {
  animation: bounce 1s infinite;
}
