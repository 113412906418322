.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .loading-animation {
    font-size: 24px;
    text-align: center;
    position: relative;
  }
  
  .loading-text {
    position: relative;
    display: inline-block;
  }
  
  .cracked {
    overflow: hidden;
  }
  
  .crack-text {
    position: relative;
    z-index: 1;
  }
  
  .crack-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('assets/crack-image.png');
    background-size: cover;
    opacity: 0.5;
    z-index: 2;
  }
  
  .flashing-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    animation: flash 0.5s infinite alternate;
  }
  
  @keyframes flash {
    from { opacity: 0.5; }
    to { opacity: 1; }
  }