body {
  font-family: "Roboto", sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #123836;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-header h1 {
  margin: 0;
  font-size: 24px;
  color: #a0afaf;
}

.header-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.service-logo {
  width: 50px; /* Adjust this value as needed */
  height: auto;
  margin-bottom: 10px;
}

.service-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.netcompany-logo {
  height: 20px;
  margin-right: 15px;
  filter: brightness(0) invert(1);
}

.menu-icon {
  cursor: pointer;
  z-index: 1000;
  font-size: 24px;
}

.dashboard {
  margin: 0 auto;
  padding: 20px;
}

.service-card {
  border-radius: 8px;
  padding: 20px;
  color: #333;
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-card.clickable {
  cursor: pointer;
}

.service-card.clickable:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.service-link {
  text-decoration: none;
  color: inherit;
}

.service-card.green {
  background-color: #4caf50;
  color: white;
}

.service-card.amber {
  background-color: #ffc107;
  color: black;
}

.service-card.red {
  background-color: #f44336;
  color: white;
}

.issues-table {
  width: 100%;
  border-collapse: collapse;
}

.menu {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #f8f9fa;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.menu.open {
  right: 0;
  background-color: #d0d7d7;
}

.menu-content {
  padding: 20px;
}

.menu-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.menu-item {
  margin-bottom: 20px;
}

.menu h2 {
  margin-bottom: 20px;
  color: #333;
}

.menu label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.menu input[type="number"] {
  width: 15%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 50px;
}

.toggle input {
  display: none;
}

.slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 24px;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 999;
}

.overlay.active {
  display: block;
}

.back-button {
  display: inline-block;
  margin: 20px;
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.service-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.debug-banner {
  background-color: #ffc107;
  color: #000;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
}

.menu {
  background-color: #fff;
  border-left: 1px solid #e0e0e0;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.menu-content {
  padding: 30px;
}

.menu h2 {
  color: #003a70;
  margin-bottom: 30px;
}

.menu-item {
  margin-bottom: 25px;
}

.menu label {
  display: block;
  margin-bottom: 10px;
  color: #003a70;
  padding-left: 2px;
}

.toggle {
  display: flex;
  align-items: center;
}

.toggle input {
  margin-right: 10px;
}

.slider {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #003a70;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.save-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
}

.menu-item.debug-mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.menu-item.debug-mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-item.debug-mode label {
  margin-bottom: 0;
  margin-right: 10px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #23ff06;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.hidden {
  display: none;
}
